@import 'https://fonts.googleapis.com/css?family=Open+Sans|Roboto:300';
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css");
.l-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}
@media (min-width: 768px) {
  .l-container {
    width: 768px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 992px) {
  .l-container {
    width: 992px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 1200px) {
  .l-container {
    width: 1170px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .l-visible-only-xs {
    display: none;
  }
}

@media (max-width: 767px) {
  .l-hidden-xs {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .l-hidden-sm {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .l-hidden-md {
    display: none;
  }
}

@media (min-width: 1200px) {
  .l-hidden-lg {
    display: none;
  }
}

/** @define Button */
.Button--primary {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2px;
  color: #fff;
  background-color: #f5ab3c;
  border-radius: 4px;
  border: 1px solid #222;
  padding: 13px 16px;
  display: inline-block;
  width: auto;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  transition: 1s;
  /* postcss-bem-linter: ignore */
}
.Button--primary:hover, .Button--primary:active {
  transition: 1s;
  background-color: #f1c87c;
  opacity: 0.6;
}
@media (max-width: 767px) {
  .Button--mobileHero {
    height: 80px;
    padding: 8px;
    background-color: #eee;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
}

/** @define Link */
.Link--primary {
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  /* postcss-bem-linter: ignore */
  /* postcss-bem-linter: ignore */
  color: #f5ab3c;
}
.Link--primary:hover {
  text-decoration: none;
}
.Link--primary:active {
  text-decoration: underline;
}

/** @define Text */
.Text {
  font-size: 16px;
  line-height: 24px;
  color: #333;
}
.Text--bold {
  font-weight: 700;
}
.Text--large {
  font-size: 20px;
  line-height: 32px;
}
.Text--small {
  font-size: 14px;
  line-height: 20px;
}
.Text--extrasmall {
  font-size: 11px;
  line-height: 13px;
}

.Messages-area {
  padding: 20px;
  height: 400px;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Messages-area-container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.Messages-bubble {
  background-color: #bbdefb;
  padding: 20px;
  border-radius: 4px;
  min-width: 300px;
  word-wrap: break-word;
}

.Messages-close {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.Messages-bubble {
  background-color: #bbdefb;
  padding: 20px;
  border-radius: 4px;
  max-width: 300px;
  word-wrap: break-word;
}

.Messages-bubble .Messages-time {
  font-size: 12px;
  color: #757575;
  text-align: right;
}

.Messages-area .Messages-bubble.current-user {
  margin-left: auto;
  background-color: #a5d6a7;
}

.form-control {
  width: 60%;
}

.float-right {
  margin-left: auto;
}

.float-left {
  margin-right: auto;
}

.Messages-area-sendmsg {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Messages-area-deal {
  display: flex;
  margin-top: 30px;
}

.Messages-area-deal .form-control {
  width: 100%;
}

.Messages-area-deal .btn {
  margin-left: 10px;
}

.Messages-area-deal .btn-primary {
  background-color: #4caf50;
  border-color: #4caf50;
}

.Messages-area-deal .btn-primary:hover {
  background-color: #388e3c;
  border-color: #388e3c;
}

.Messages-area-deal .btn-primary:focus {
  box-shadow: none;
}

.Messages-area-deal .btn-primary:active {
  background-color: #388e3c;
  border-color: #388e3c;
}

.Messages-area-deal .btn-primary:active:focus {
  box-shadow: none;
}

.Messages-area-deal .btn-primary:active:hover {
  background-color: #388e3c;
  border-color: #388e3c;
}

.Messages-area-deal .btn-primary:active:active {
  background-color: #388e3c;
  border-color: #388e3c;
}

.Messages-area-deal .btn-primary:active:active:focus {
  box-shadow: none;
}

.Messages-area-deal .btn-primary:active:active:hover {
  background-color: #388e3c;
  border-color: #388e3c;
}

.Messages-area-deal .btn-primary:active:active:active {
  background-color: #388e3c;
  border-color: #388e3c;
}

.Messages-area-deal .btn-primary:active:active:active:focus {
  box-shadow: none;
}

.mx-3 {
  display: flex;
  width: 100%;
  margin-top: 30px;
  flex-grow: 1;
}

.form-control:focus {
  box-shadow: none;
}

.Messages-area-sendmsg {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Messages-area-deal {
  display: flex;
  margin-top: 30px;
}

.Messages-bubble-sender {
  background-color: #a5d6a7;
}

@media screen and (max-width: 767px) {
  .form-control {
    width: 100%;
  }
  .Messages-area .Messages-bubble {
    max-width: 250px;
  }
}


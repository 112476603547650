@import "../../assets/sass/Common.scss";

.Admin{
    padding-top: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;

    &-table{
        padding: 20px;
        overflow-x: scroll;
    }

    &-container
    {
        margin: 24px 0;
    }

    &-messageClient
    {
        border: 1px solid $gray-3;
        border-radius: 4px;
        padding: 8px;
    }
}

.MuiButton-label{
    font-family: JosefinSans;
}

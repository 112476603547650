@import '../../assets/sass/Common.scss';

.HomePage {
  align-items: flex-start;
  height: 100%;
  overflow: hidden;
  width: 100%;

  &-container
  {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    // background-color: $brand-orange;
    background: linear-gradient(330deg, #e4c886 0%, #b1ec73 25%, #917045 50%, #db7777 75%, #dad45b 100%);
  }

  &-splash
  {
    display: flex;
    height: 300px;
    width: 100%;
    position: relative;
  }

  &-backgroundOverlay {
    background-color: #0000008f;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }
  &-greeting
  {
    text-align: center;
    background-color: $white;
    z-index: 9;
    margin-top: -8px;
    padding: 32px;
    border-radius: 4px;
    border: 1px solid $brand-primary;
    font-weight: $font-weight-normal;
    transition: margin-top 1s ease-in;
  }

  &-greeting.HomePage-animategreeting
  {
    margin-top: -54px;
    transition: margin-top 1s ease-in;
  }

  &-options{
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin-top: 16px;
  }

  &-option{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1 1 160px;
    min-height: 200px;
    padding: 16px;
    background-color: $gray-5;
    transition: background-color 0.1s ease-in;
    border: $border-1;
    border-radius: 4px;
    cursor: pointer;
    margin: 16px 8px;

    &.hover{
      background-color: $brand-light-green;
      transition: background-color 0.1s ease-in;
    }

    &:hover{
      background-color: $brand-light-green;
      margin-top: -1px;
      transition: margin-top 0.1s ease-in;
    }

    @include bplte(xs){
      flex-direction: column;
    }
  }

  &-errorMsg
  {
    font-size: xx-small;
    color: $collapsing-banner-background;
  }

  &-cashAppField
  {
    font-size: xx-small;
    color: $gray-6;
    margin-top:16px;
  }
}

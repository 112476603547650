/** @define Button */
@mixin Button--primary
{
  @include font-sizes(16px, 24px);

  letter-spacing: 2px;
  color: $white;
  background-color: $brand-primary;
  border-radius: 4px;
  border: 1px solid $gray-1;
  padding: 13px 16px;
  display: inline-block;
  width: auto;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  transition: 1s;

  /* postcss-bem-linter: ignore */
  &:hover,
  &:active
  {
    transition: 1s;
    background-color: $brand-secondary;
    opacity: 0.6;
  }
}

@mixin Button--mobileHero
{
  @include bplte(xs)
  {
    height: 80px;
    padding: 8px;
    background-color: $dark-overlay;
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
}

.Button
{
  &--primary
  {
    @include Button--primary;
  }

  &--mobileHero
  {
    @include Button--mobileHero;
  }
}

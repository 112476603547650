@import '../../assets/sass/Common.scss';

.Footer-default {
    justify-content: center;
    background-color: $brand-primary;
    display: flex;
    height: 65px;
    overflow: hidden;
    width: 100%;
    padding: 16px 8px;
    align-items: center;
  }

  .frame-142 {
    justify-content: space-between;
    display: flex;
    margin-top: 4px;
    width: 80%;
    padding: 0 16px;
  }

  .Footer-greeting{
    color: white;
  }

  .frame-255 {
    align-items: center;
    display: flex;
    padding: 0 8px;
  }

  .text-10 {
    letter-spacing: 0;
    white-space: nowrap;
  }

  .text-11 {
    letter-spacing: 0;
    margin-left: 16px;
    white-space: nowrap;
  }

  .text-12 {
    letter-spacing: 0;
    margin-left: 16px;
    white-space: nowrap;
  }

  .frame-140 {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 8px;
  }

  .twitter-negative {
    height: 16px;
    width: 16px;
    transition: transform 1s;
  }

  .instagram-negative {
    height: 16px;
    margin-left: 16px;
    width: 16px;
    transition: transform 1s;
  }

  .twitter-negative:hover,
  .instagram-negative:hover
  {
    transform: rotate(-315deg);
    transition: transform 1s;
  }

  @media only screen and (max-width: 600px) {
    .frame-142 {
      width: 100%;
      padding: 0;
    }
  }

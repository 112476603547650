.App {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &-content
    {
        width: 100%;
        height: 100%;
    }
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.App-content {
  width: 100%;
  height: 100%;
}


.se-pre-con {
  background: url(../../../static/img/bhukyherogif.gif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.no-js #loader {
  display: none;
}

.js #loader {
  display: block;
  position: absolute;
  left: 100px;
  top: 0;
}

.se-pre-con {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}


@import 'https://fonts.googleapis.com/css?family=Open+Sans|Roboto:300';
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css");
.l-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}
@media (min-width: 768px) {
  .l-container {
    width: 768px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 992px) {
  .l-container {
    width: 992px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 1200px) {
  .l-container {
    width: 1170px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .l-visible-only-xs {
    display: none;
  }
}

@media (max-width: 767px) {
  .l-hidden-xs {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .l-hidden-sm {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .l-hidden-md {
    display: none;
  }
}

@media (min-width: 1200px) {
  .l-hidden-lg {
    display: none;
  }
}

/** @define Button */
.Button--primary {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2px;
  color: #fff;
  background-color: #f5ab3c;
  border-radius: 4px;
  border: 1px solid #222;
  padding: 13px 16px;
  display: inline-block;
  width: auto;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  transition: 1s;
  /* postcss-bem-linter: ignore */
}
.Button--primary:hover, .Button--primary:active {
  transition: 1s;
  background-color: #f1c87c;
  opacity: 0.6;
}
@media (max-width: 767px) {
  .Button--mobileHero {
    height: 80px;
    padding: 8px;
    background-color: #eee;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
}

/** @define Link */
.Link--primary {
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  /* postcss-bem-linter: ignore */
  /* postcss-bem-linter: ignore */
  color: #f5ab3c;
}
.Link--primary:hover {
  text-decoration: none;
}
.Link--primary:active {
  text-decoration: underline;
}

/** @define Text */
.Text {
  font-size: 16px;
  line-height: 24px;
  color: #333;
}
.Text--bold {
  font-weight: 700;
}
.Text--large {
  font-size: 20px;
  line-height: 32px;
}
.Text--small {
  font-size: 14px;
  line-height: 20px;
}
.Text--extrasmall {
  font-size: 11px;
  line-height: 13px;
}

.PurchaseReel {
  display: flex;
  flex-direction: column;
}
.PurchaseReel h2 {
  font-weight: 300;
  padding: 16px 8px;
  margin-bottom: 32px;
  border-radius: 4px;
  background-color: #f1c87c;
  color: #6b7c93;
}
.PurchaseReel h3 {
  font-weight: 300;
  font-size: medium;
  position: relative;
  animation: swing 2s ease-in-out infinite;
}
.PurchaseReel #sintel3,
.PurchaseReel #sintel2,
.PurchaseReel #sintel1 {
  position: relative;
}
.PurchaseReel-voiceNote {
  padding-bottom: 32px;
  border-bottom: 1px solid #999;
}
.PurchaseReel-voiceNote h3 {
  background-color: #f5ab3c;
  padding: 8px;
  margin-bottom: 0;
  color: #555;
}
.PurchaseReel-eachSection {
  margin: 16px 0;
}
.PurchaseReel-levelOptions {
  display: flex;
  flex-direction: column;
}
.PurchaseReel-levelOptionsGallery {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  overflow-x: auto;
}
.PurchaseReel-galleryEntry {
  width: 33.3333333333%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #f5ab3c;
}
.PurchaseReel-galleryEntry:not(:last-child) {
  margin-right: 8px;
}
.PurchaseReel-levelOptions.PurchaseReel-eachSection {
  background-color: white;
}
.PurchaseReel-videoDescription {
  margin: 8px;
  color: #555;
}
.PurchaseReel-videoDescription h3 {
  font-weight: bold;
}
.PurchaseReel-stripePayment {
  margin: 16px auto;
  width: 90%;
  background-color: #f1c87c;
  padding: 24px;
  border-radius: 4px;
}
@media (max-width: 767px) {
  .PurchaseReel-stripePayment {
    width: 90%;
  }
  .PurchaseReel-stripePayment .__PrivateStripeElement {
    width: 270px;
  }
}
.PurchaseReel-placeOrder {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.PurchaseReel-placeOrder pre {
  color: #b00000;
  font-size: xx-small;
  background-color: #defacf;
  padding: 8px;
  border-radius: 4px;
}
.PurchaseReel .styles_poster__1JohY {
  height: 170px;
  object-fit: cover;
}
.PurchaseReel .multiSelectContainer input {
  width: 300px;
  cursor: pointer;
}
.PurchaseReel ._1ceqH ._1lB9c {
  display: none;
}
.PurchaseReel ._dt3-T._dt3-T,
.PurchaseReel ._3bC73 {
  background-color: #f1c87c;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.PurchaseReel ._1ceqH ._1YOWG ._eV_dK {
  text-align: center;
}
.PurchaseReel ._1ceqH ._1Yplu {
  display: flex;
  justify-content: center;
}
.PurchaseReel ._1ceqH ._2fG9h {
  padding-top: 0;
}
.PurchaseReel ._1Pz2d._37kfa {
  display: none;
}

@keyframes swing {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}


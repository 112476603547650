@import '../../assets/sass/Common.scss';

.MenuItem
{
    &-galleryEntry{
        width: 25vw;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 4px;
        background-color: $brand-primary;

        &:not(:last-child){
            margin-right: 8px;
        }

        @include bplte(xs){
            width: 75vw;
        }
    }

    h3
    {
        font-weight: $font-weight-semibold;
        font-size: medium;
    }

    &-video
    {
        width: 100%;
        height: 260px;
        object-fit: cover;
    }

    &-videoDescription
    {
        padding: 8px;
        color: white;
    }

    &-selections
    {
        display: flex;
        justify-content: space-between;
        padding: 8px;

        span {
            cursor: pointer;
            padding: 8px;
            border-radius: 4px;

            &:hover {
              background-color: $brand-3;
            }
        }
    }

    &-selectItem
    {
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: white;
        font-size: 30px;
        padding: 8px;
        cursor: pointer;

        &--updateCart
        {
            border: 1px solid $brand-secondary;
            padding: 2px;
            border-radius: 4px;
            min-width: 24px;
            display: flex;
            justify-content: center;

            &:hover{
                color: black;
            }
        }

        &--itemCount
        {
            background-color: white;
            color: black;
            border-radius: 4px;
            padding: 0px 16px;
            width: 24px;
            display: flex;
            justify-content: center;
        }
    }
}
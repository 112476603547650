@import "../../assets/sass/Common.scss";

.Messages-area {
  padding: 20px;
  height: 400px;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Messages-area-container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.Messages-bubble {
  background-color: #bbdefb;
  padding: 20px;
  border-radius: 4px;
  min-width: 300px;
  word-wrap: break-word;
}

.Messages-close {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.Messages-bubble {
  background-color: #bbdefb;
  padding: 20px;
  border-radius: 4px;
  max-width: 300px;
  word-wrap: break-word;
}

.Messages-bubble .Messages-time {
  font-size: 12px;
  color: #757575;
  text-align: right;
}

.Messages-area .Messages-bubble.current-user {
  margin-left: auto;
  background-color: #a5d6a7;
}

.form-control {
  width: 60%;
}

.float-right {
  margin-left: auto;
} 

.float-left{
  margin-right: auto;
}

.Messages-area-sendmsg
{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Messages-area-deal {
  display: flex;
  margin-top: 30px;
}

.Messages-area-deal .form-control {
  width: 100%;
}

.Messages-area-deal .btn {
  margin-left: 10px;
}

.Messages-area-deal .btn-primary {
  background-color: #4caf50;
  border-color: #4caf50;
}

.Messages-area-deal .btn-primary:hover {
  background-color: #388e3c;
  border-color: #388e3c;
}

.Messages-area-deal .btn-primary:focus {
  box-shadow: none;
}

.Messages-area-deal .btn-primary:active {
  background-color: #388e3c;
  border-color: #388e3c;
}

.Messages-area-deal .btn-primary:active:focus {
  box-shadow: none;
}

.Messages-area-deal .btn-primary:active:hover {
  background-color: #388e3c;
  border-color: #388e3c;
}

.Messages-area-deal .btn-primary:active:active {
  background-color: #388e3c;
  border-color: #388e3c;
}

.Messages-area-deal .btn-primary:active:active:focus {
  box-shadow: none;
}

.Messages-area-deal .btn-primary:active:active:hover {
  background-color: #388e3c;
  border-color: #388e3c;
}

.Messages-area-deal .btn-primary:active:active:active {
  background-color: #388e3c;
  border-color: #388e3c;
}

.Messages-area-deal .btn-primary:active:active:active:focus {
  box-shadow: none;
}

.mx-3 {
  display: flex;
  width: 100%;
  margin-top: 30px;
  flex-grow: 1;
}

.form-control:focus {
  box-shadow: none;
}

.Messages-area-sendmsg {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Messages-area-deal {
  display: flex;
  margin-top: 30px;
}

.Messages-bubble-sender
{
  background-color: #a5d6a7;
}

@media screen and (max-width: 767px) {
  .form-control {
    width: 100%;
  }

  .Messages-area .Messages-bubble {
    max-width: 250px;
  }
}
